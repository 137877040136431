import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Path } from './Path'

export interface Props {
  description?: string
  image?: ImageProps
  label?: string
  title?: string
}

export const Quotes = memo(function Quotes({
  description,
  image,
  label,
  title,
}: Props) {
  if (!description) {
    return null
  }

  return (
    <Container>
      {image?.src ? (
        <LazyLoadComponent threshold={600}>
          <Path URL={image.src} />
        </LazyLoadComponent>
      ) : null}

      {label ? <Label>{label}</Label> : null}

      {title ? <Title>{title}</Title> : null}

      <FadeInUp>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  margin-top: 18.75rem;
  padding: calc(28vh + 4.375rem) 8.333vw 15.625rem;
  position: relative;
  text-align: center;

  @media (max-width: 1199px) {
    margin-top: 12.5rem;
    padding: 12.5rem 1.5rem 5.375rem;
  }
`

const Style = css`
  color: ${theme.colors.variants.neutralLight5};
  font-family: ${theme.fontFamily.paragraph};
  font-size: 1.1875rem;
  line-height: 1.875rem;
  opacity: 0.3;
  position: absolute;

  @media (max-width: 1199px) {
    display: inline;
    margin: 0 0.1875rem;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    transform: none;
  }
`

const Label = styled.div`
  top: 50%;
  left: 8.333vw;
  transform-origin: left top;
  transform: rotate(-90deg) translateX(-50%);

  ${Style}
`

const Title = styled.div`
  right: 8.333vw;
  bottom: 50%;
  transform-origin: right bottom;
  transform: rotate(-90deg) translateX(50%);

  ${Style}
`

const Description = styled.div`
  max-width: 53.625rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.75rem;
  font-weight: 300;
  line-height: 3.75rem;
  margin: auto;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 2.1875rem;
    line-height: 2.625rem;
    margin-top: 1.25rem;
  }
`
